import { render, staticRenderFns } from "./chemReview.vue?vue&type=template&id=6b680579"
import script from "./chemReview.vue?vue&type=script&lang=js"
export * from "./chemReview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\PROJECT\\KNCO\\FrontEnd\\KNCOFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b680579')) {
      api.createRecord('6b680579', component.options)
    } else {
      api.reload('6b680579', component.options)
    }
    module.hot.accept("./chemReview.vue?vue&type=template&id=6b680579", function () {
      api.rerender('6b680579', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/mdm/mam/review/chemReview.vue"
export default component.exports